import React from 'react';
import styled from '@emotion/styled';
import { graphql, Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import ArrowBack from 'assets/svgs/arrow_back.svg';
import SEO from 'components/SEO';
import { BREAKPOINTS, INTERNAL_LINKS, MOBILE_SIDE_PADDING } from 'utils/constants';

interface IImage {
  image: string;
}

export const query = graphql`
  query NewsQuery($id: String) {
    prismicNewsPage(id: { eq: $id }) {
      data {
        author_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        author_name {
          text
          html
          raw
        }
        author_quote {
          text
          html
          raw
        }
        og_description {
          text
          html
          raw
        }
        og_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        og_title {
          text
          html
          raw
        }
        page_description {
          text
          html
          raw
        }
        page_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        page_title {
          text
          html
          raw
        }
        post_content {
          text
          html
          raw
        }
        post_image {
          alt
          copyright
          gatsbyImageData(placeholder: BLURRED, height: 450)
        }
        post_title {
          text
          html
          raw
        }
        post_type {
          text
          html
          raw
        }
        twitter_description {
          text
          html
          raw
        }
        twitter_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        twitter_title {
          text
          html
          raw
        }
        post_date
      }
    }
  }
`;

const PageContainer = styled.div((props) => ({
  backgroundColor: props.theme.colors.backgroundWhite,
  color: props.theme.colors.dark,
  padding: '186px min(300px, 18%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '100vh',
  letterSpacing: 1,
  [BREAKPOINTS[800]]: {
    padding: `174px ${MOBILE_SIDE_PADDING}`,
  },
}));

const Container = styled.div({
  maxWidth: 1000,
});

const BackButton = styled(Link)((props) => ({
  color: props.theme.colors.dark,
  textDecoration: 'none',
}));

const Title = styled.div({
  h1: {
    fontSize: 40,
    fontWeight: 'normal',
    margin: '56px 0 40px 0',
  },
});

const InfoContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontSize: 15,
  marginBottom: 48,
});

const TypeText = styled.div((props) => ({
  backgroundColor: props.theme.colors.lightGray,
  padding: '2px 4px',
  borderRadius: 2,
}));

const Dot = styled.div({
  margin: '0 8px',
});

const StyledArrow = styled(ArrowBack)({
  marginRight: 5,
  marginBottom: 2,
});

const Dash = styled.div({
  width: 100,
  height: 0,
  borderBottom: '1px solid rgba(196, 196, 196, 0.7)',
  margin: '45px 0',
});

const AuthorContainer = styled.div({
  display: 'flex',
  [BREAKPOINTS[800]]: {
    flexDirection: 'column',
  },
});

const AuthorAvatar = styled.div<IImage>((props) => ({
  backgroundImage: `url(${props.image})`,
  backgroundPosition: 'center center',
  backgroundSize: 'auto 100%',
  backgroundRepeat: 'no-repeat',
  borderRadius: 45,
  marginRight: 24,
  height: 74,
  minWidth: 74,
  maxWidth: 74,
  [BREAKPOINTS[800]]: {
    marginBottom: 8,
  },
}));

const AuthorName = styled.div({
  fontSize: 15,
  marginBottom: 16,
  letterSpacing: 1,
});

const AuthorQuote = styled.div({
  opacity: 0.7,
  fontStyle: 'italic',
  fontWeight: 100,
  fontSize: 15,
  lineHeight: '23px',
});

const ContentContainer = styled.div({
  marginTop: 60,
  ['p, li, ol']: {
    color: 'rgba(0, 0, 0, 0.7)',
    fontSize: 15,
    lineHeight: '27px',
    fontWeight: 300,
  },
  img: {
    maxWidth: '100%',
    maxHeight: 500,
  },
  '.block-img': {
    textAlign: 'center',
  },
});

const NewsPage = ({ data }) => {
  const post = data.prismicNewsPage.data;

  const isAuthorAdded = !!post.author_image.url;
  const image = getImage(post.post_image);

  return (
    <div>
      <SEO
        pageTitle={post?.page_title?.text}
        ogTitle={post?.og_title?.text}
        twitterTitle={post?.twitter_title?.text}
        pageDescription={post?.page_description?.text}
        ogDescription={post?.og_description?.text}
        twitterDescription={post?.twitter_description?.text}
        pageImage={post?.page_image?.url}
        pageImageAlt={post?.page_image?.alt}
        twitterImage={post?.twitter_image?.url}
        twitterImageAlt={post?.twitter_image?.alt}
        ogImage={post?.og_image?.url}
        ogImageAlt={post?.og_image?.url?.alt}
      />
      <PageContainer>
        <Container>
          <BackButton to={INTERNAL_LINKS.NEWS}>
            <StyledArrow /> BACK
          </BackButton>
          <Title>
            <RichText render={post.post_title?.raw} />
          </Title>
          <InfoContainer>
            {!!post.post_type?.text && <TypeText>{post.post_type?.text?.toUpperCase()}</TypeText>}
            <Dot>&bull;</Dot>
            <div>{post.post_date}</div>
          </InfoContainer>
          {image && (
            <GatsbyImage
              image={image}
              alt={post?.post_title?.text}
              style={{ borderRadius: 4, width: '100%' }}
              loading="lazy"
            />
          )}
          <ContentContainer>
            <RichText render={post.post_content?.raw} />
          </ContentContainer>
          {isAuthorAdded && (
            <>
              <Dash />
              <AuthorContainer>
                <AuthorAvatar image={post.author_image?.url} />
                <div>
                  <AuthorName>{post.author_name?.text?.toUpperCase()}</AuthorName>
                  <AuthorQuote>{post.author_quote?.text}</AuthorQuote>
                </div>
              </AuthorContainer>
            </>
          )}
        </Container>
      </PageContainer>
    </div>
  );
};

export default NewsPage;
